import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Typography,
  WithStyles,
} from '@material-ui/core';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { setNavigationUpwardTarget, setTitle } from '@meeva/service-client-core/redux/interface/actions';
import { ItemInventoryLocalTask, ShelfListing } from '@meeva/service-client-core/interfaces/ItemInventoryInterface';
import { markTasksAsReady } from '../../redux/modules/stockTake/operations';
import { ReplaceReturnType } from '@meeva/service-client-core/redux/store';
import { getItemInventoryEventsByInstruction } from '@meeva/service-client-core/redux/event/selectors';
import { getOnlineStatus } from '@meeva/service-client-core/redux/interface/selectors';
import ConfirmDialog from '@meeva/service-client-core/modules/common/components/ConfirmDialog';
import { AppMode } from '@meeva/service-client-core/redux/appBehavior/appModeSlice';
import FindInPage from '@material-ui/icons/FindInPage';
import { getConfig } from '@meeva/service-client-core/redux/common/app/selectors';
import { getLocations } from '@meeva/service-client-core/utils/inventoryTaskHelper';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    actionTitle: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    timelineResult: {
      fontWeight: 'bold',
    },
    timelineDotSuccess: {
      backgroundColor: theme.palette.success.light,
    },
    timelineDotWarning: {
      backgroundColor: theme.palette.warning.light,
    },
    loadingModal: {
      display: 'flex',
    },
    loadingSpinner: {
      margin: 'auto',
    },
  });

const StyledTimelineItem = withStyles({
  root: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem);

interface ShelfSummaryRouteProps {
  type: string;
}

interface ShelfSummaryProps {
  tasks: Array<[number, ShelfListing]>;
}

interface ShelfSummaryConnectedProps
  extends ShelfSummaryProps,
    RouteComponentProps<ShelfSummaryRouteProps>,
    WithStyles {
  setTitle: typeof setTitle;
  markTasksAsReady: ReplaceReturnType<typeof markTasksAsReady, Promise<void>>;
  config?: any;
}

// TODO: Mit ShelfSummary zusammenfügen. Nach selecten der Shelf aus der Route, könnte die ShelfSummary die TaskSummary rendern

const TasksSummary = ({ config, setTitle, ...props }: ShelfSummaryConnectedProps) => {
  const localConfirmTextConfig = config.itemInventory?.confirmDialog?.localConfirmText;
  const [isLoading, setLoading] = React.useState(true);
  const [locations, setLocations] = React.useState(new Map<string, string>([]));
  const [tasks, setTasks] = React.useState([] as ItemInventoryLocalTask[]);
  const [numTotalCount, setNumTotalCount] = React.useState(0);
  const [numTotalCountedArticles, setNumTotalCountedArticles] = React.useState(0);
  const [numTotalStockDifference, setNumTotalStockDifference] = React.useState(0);
  const [numTotalStockDifferenceItems, setNumTotalStockDifferenceItems] = React.useState(0);
  const [isBackgroundProcessActive, setBackgroundProcessActive] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [isChecked, setChecked] = React.useState<boolean>(false);
  const activeClosingDateEvents = useSelector((state) => getItemInventoryEventsByInstruction(state, 'closingDate'));
  const isOnline = useSelector(getOnlineStatus);
  //TODO: Implement RootState (@meeva/service-client-core/redux/store) with store.getState types
  const appModeState = useSelector((state: any) => state.app.mode);
  const ignoreCurrentStock = config.physicalCount?.ignoreCurrentStock || false;

  const dispatch = useDispatch();
  const { tasks: tasksByShelf, classes, history, markTasksAsReady } = props;

  useEffect(() => {
    const allTasks = [] as ItemInventoryLocalTask[];
    tasksByShelf.forEach(([, shelf]) => allTasks.push(...shelf.tasks));
    setTasks(allTasks);
    setLoading(false);
  }, [tasksByShelf]);

  const returnToInventoryStart = useCallback(() => {
    history.push('/itemInventory');
  }, [history]);

  const sendStockTakes = useCallback(() => {
    setBackgroundProcessActive(true);

    // Aufgaben welche in allen Standorten gezählt wurden
    const completedShelfTasks = tasks.filter((task) => task.countings.length > 0);

    (async () => {
      await markTasksAsReady(completedShelfTasks);
      setBackgroundProcessActive(false);
      history.push('/itemInventory');
    })();
  }, [history, locations, markTasksAsReady, tasks]);

  const showShelfDifferences = useCallback(() => {
    history.push(`/itemInventory/type/${props.match.params.type}/differences`);
  }, [history]);

  React.useEffect(() => {
    setTitle('Zusammenfassung');

    const tmpLocations = getLocations();
    setLocations(tmpLocations);

    dispatch(
      setNavigationUpwardTarget(
        `/itemInventory/type/${props.match.params.type}/location/${[...tmpLocations.keys()].pop()}`
      )
    );
  }, [setTitle]);

  React.useEffect(() => {
    // Aufgaben welche in allen Standorten gezählt wurden
    const countingSum = tasks.reduce(
      (carry, task) => carry + task.countings.reduce((acc, counting) => acc + counting[1].amount, 0),
      0
    );
    const completedShelfTasks = tasks.filter((task) => task.countings.length > 0);

    setNumTotalCount(countingSum);
    setNumTotalCountedArticles(completedShelfTasks.length);

    if (activeClosingDateEvents.length === 0) {
      // Summe gezählter Bestand
      const countedStock = completedShelfTasks.reduce((carry, task) => {
        const counted = task.countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0);
        const stockDiff = Math.abs((counted - task.item.stockQty) * (task.item.priceInformation?.stock?.price || 0));

        return stockDiff >= 15
          ? task.countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0)
          : task.item.stockQty;
      }, 0);

      // Summe erwarteter Bestand
      const expectedStock = completedShelfTasks.reduce((carry, task) => {
        return carry + task.item.stockQty;
      }, 0);
      setNumTotalStockDifference(countedStock - expectedStock);

      const itemsByStockPriceDiff = completedShelfTasks.filter(({ countings, item }) => {
        const counted = countings.reduce((cCarry, counting) => cCarry + counting[1].amount, 0);
        const stockDiff = Math.abs((counted - item.stockQty) * (item.priceInformation?.stock?.price || 0));

        if (stockDiff >= 15) {
          return item;
        }
      });

      setNumTotalStockDifferenceItems(itemsByStockPriceDiff.length);
    }
  }, [tasks, locations]);

  if (isLoading) {
    return (
      <Container>
        <Box mt={8} textAlign="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container>
        <Box mt={1}>
          <Paper>
            <Typography variant="h5" component="h2" className={classes.title}>
              Freies Scannen
            </Typography>

            <Timeline align="left">
              <StyledTimelineItem>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotSuccess}>
                    <CheckIcon />
                  </TimelineDot>
                  {!ignoreCurrentStock && activeClosingDateEvents.length === 0 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  Gezählt:
                  <div className={classes.timelineResult}>
                    {numTotalCountedArticles} Artikel / {numTotalCount} Stück
                  </div>
                </TimelineContent>
              </StyledTimelineItem>
              {!ignoreCurrentStock && activeClosingDateEvents.length === 0 && (
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      className={
                        Math.abs(numTotalStockDifferenceItems) > 0
                          ? classes.timelineDotWarning
                          : classes.timelineDotSuccess
                      }
                    >
                      {Math.abs(numTotalStockDifferenceItems) > 0 ? <WarningIcon /> : <CheckIcon />}
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    Artikel mit Differenz:
                    <div className={classes.timelineResult}>{numTotalStockDifferenceItems} Artikel</div>
                  </TimelineContent>
                </StyledTimelineItem>
              )}
            </Timeline>
          </Paper>
        </Box>

        <Box mt={1}>
          <Paper>
            <Typography className={classes.actionTitle}>Was möchtest du als nächstes tun?</Typography>
            <List component="nav">
              {!ignoreCurrentStock && (
                <ListItem button color="primary" disabled={numTotalStockDifference === 0}>
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Differenzen prüfen" onClick={showShelfDifferences} />
                </ListItem>
              )}
              <ListItem button color="primary">
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText primary="Inventur fortsetzen" onClick={returnToInventoryStart} />
              </ListItem>
              {AppMode.MISSING_ITEM_ANALYSIS_MODE === appModeState ? (
                <ListItem button color="primary" onClick={() => history.push('/missing-item-analysis')}>
                  <ListItemIcon>
                    <FindInPage />
                  </ListItemIcon>
                  <ListItemText primary="Zurück zur Analyse-Übersicht" />
                </ListItem>
              ) : (
                <ListItem button color="primary" onClick={() => setConfirmDialogOpen(true)}>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Absenden" />
                </ListItem>
              )}
            </List>
          </Paper>
        </Box>

        <Modal
          disableBackdropClick
          disableEnforceFocus
          disableAutoFocus
          className={classes.loadingModal}
          open={isBackgroundProcessActive}
          onClose={() => setBackgroundProcessActive(false)}
        >
          <CircularProgress className={classes.loadingSpinner} />
        </Modal>
      </Container>

      <ConfirmDialog
        open={confirmDialogOpen}
        title="Achtung"
        contentText={
          localConfirmTextConfig
            ? 'Sind Sie sicher, dass Sie die Daten absenden möchten?'
            : 'Bestätigung am Computer erforderlich'
        }
        cancelText={localConfirmTextConfig && 'Abbrechen'}
        confirmText="Okay"
        handleClose={() => setConfirmDialogOpen(false)}
        disabledConfirm={!isOnline && !isChecked}
        handleConfirm={sendStockTakes}
      >
        {!isOnline ? (
          <FormGroup>
            <FormControlLabel
              sx={{ fontSize: '1rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}
              control={<Checkbox onClick={() => setChecked(!isChecked)} />}
              label="Sobald das Gerät online ist, erfolgt die Datenübertragung."
            />
          </FormGroup>
        ) : null}
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    config: getConfig(state),
  }),
  {
    setTitle,
    markTasksAsReady,
  }
)(withRouter(withStyles(styles)(TasksSummary))) as React.ComponentType<ShelfSummaryProps>;
