import '@fontsource/roboto';
import App from '@meeva/service-client-core/modules/app';
import ItemInventoryModule from '@meeva/service-client-item-inventory';
import { Route } from 'react-router';
import pages from './modules/pages';
import navigations from './modules/navigations';

App.addModule(ItemInventoryModule);
App.addRoutes(pages as unknown as Route[]);
App.addNavigations(navigations);
App.addServiceWorkers([]);

const config = {
  graphQLUseElasticSearchPagination: true,
  audioFeedback: {
    enabled: true,
    audioOnStatus: ['all'], // 'all' | 'success' | 'warning' | 'error
    audioOnType: ['all'], // 'all' | 'item' | 'businessUnit' | 'container' | 'others'
  },
  itemLoader: {
    merchandiseHierarchyItem: {
      statusType: {
        skip: true,
      },
    },
    sourceForDescription: 'name',
    query: {
      customItemNo: 'parma_number',
    },
    // Becker Preise sind nicht Filial-Individuell. Diese müssen daher bei Filial-Wechsel nicht zurückgesetzt werden,
    // und es reicht die aus den Stammdaten synchronisierten Preise zu übernehmen.
    laxPriceLoading: true,
  },
  item: {
    displayNumber: {
      defaultQualifier: 'customItemNo',
    },
    customItemNo: {
      show: true,
      hideItemNo: true,
      label: 'Art.Nr.',
      type: 'Parma',
      itemNumberDigitOffset: 0,
      itemNumberDigitLength: 8,
    },
    findItemByCode: {
      ignoreItemNo: true,
    },
    details: {
      prices: {
        retailPrice: {
          display: true,
          showValidityPeriod: false,
        },
        previousRetailPrice: {
          display: false,
          showValidityPeriod: false,
        },
        permanentPrice: {
          display: true,
          showValidityPeriod: false,
        },
        msrp: {
          display: true,
          showValidityPeriod: false,
        },
      },
    },
  },
  stockTake: {
    enable: true,
    resetNightly: false,
  },
  itemInventory: {
    showBrandInventory: true,
    containerBarcode: /^C([BGTK])(\d{8})$/,
    scanMode: {
      ifItemExistsCountUp: true,
      skipQuantityInputDialog: true,
      showNumPad: true,
    },
    confirmDialog: {
      localConfirmText: true,
    },
    inventoryItemMenu: {
      reducedMenu: true,
    },
  },
  physicalCount: {
    ignoreCurrentStock: true,
    sortTasksListByLastScan: true,
    sortTasksListWithoutCountingsByItemNo: true,
  },
  packagingSlip: {
    storeDistribution: {
      enable: false,
      allowEmptyContainer: true,
    },
  },
  stockTransfer: {
    enable: true,
    quantityChangeLimit: {
      removalByZero: true,
      lowerLimit: 20,
      upperLimit: 99_999,
    },
  },
};

App.setConfig(config);

const Element = document.getElementById('root');

if (Element) {
  App.run(Element);
}
